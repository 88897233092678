import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { persistConfig, reducer } from './reducer';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(persistConfig, reducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

const { dispatch } = store;

export { store, persistor, dispatch };
