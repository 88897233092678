import { create } from 'zustand';

// Handles data that is shared between Measure, Reduce, Share, and Certify

export type SelectedTreeType = {
  identifier: string;
  name: string;
  path: string;
  userPermissions: {
    read?: boolean;
    edit?: boolean;
    create?: boolean;
    delete?: boolean;
  };
};
export type HeadType = {
  path: string;
  identifier: string;
  name: string;
  userPermissions: {
    read?: boolean;
    edit?: boolean;
    create?: boolean;
    delete?: boolean;
  };
};

export type FootprintStoreType = {
  selectedTree: SelectedTreeType | null;
  setSelectedTree: (newState: SelectedTreeType) => void;
  heads: HeadType[];
  setHeads: (newState: HeadType[]) => void;
  activeEntityName: string;
  setActiveEntityName: (newState: string) => void;
  toReviewMeasurementsTotal: number;
  setToReviewMeasurementsTotal: (newState: number) => void;
};

const useFootprintStore = create<FootprintStoreType>((set) => ({
  selectedTree: null,
  setSelectedTree: (newSelectedTree) => {
    set({ selectedTree: newSelectedTree });
  },
  heads: [],
  setHeads: (newHeads) => {
    set({ heads: newHeads });
  },
  activeEntityName: null,
  setActiveEntityName: (newEntityName) => {
    set({ activeEntityName: newEntityName });
  },
  toReviewMeasurementsTotal: 0,
  setToReviewMeasurementsTotal: (newEntityName) => {
    set({ toReviewMeasurementsTotal: newEntityName });
  }
}));

export default useFootprintStore;
