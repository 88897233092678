import { cn } from '@/lib/utils';
import { CSSProperties } from 'react';

export default function IconButton({
  children,
  color,
  size = 'medium',
  className,
  style,
  disabled = false,
  onClick = () => undefined,
  onMouseDown = () => undefined,
  ref,
  tabIndex
}: {
  children?: React.ReactNode;
  color?: 'primary' | 'info' | 'inherit';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: (event) => void;
  onMouseDown?: (event) => void;
  ref?: React.LegacyRef<HTMLButtonElement>;
  tabIndex?: number;
}) {
  return (
    <button
      ref={ref}
      tabIndex={tabIndex}
      onClick={disabled ? null : onClick}
      onMouseDown={disabled ? null : onMouseDown}
      style={{
        transition:
          'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        ['--icon-button-bg-color-hover' as string]:
          'rgba(36, 99, 234, 0.08)',
        backgroundPosition: 'center',
        ...style
      }}
      className={cn(
        'inline-flex select-none items-center justify-center rounded-full border-none bg-transparent align-middle text-base uppercase outline-none',
        size == 'small' ? 'p-1' : size == 'medium' ? 'p-2' : 'p-3',
        disabled
          ? 'cursor-default'
          : 'cursor-pointer hover:bg-[color:--icon-button-bg-color-hover] hover:text-[color:--icon-button-color-hover] focus:text-[color:--icon-button-color-focus] active:text-[color:--icon-button-color-active]',
        disabled
          ? 'text-gray-500'
          : color == 'primary'
            ? 'text-primary'
            : color == 'info'
              ? 'text-info'
              : 'text-black',
        className
      )}
    >
      {children}
    </button>
  );
}
