import { CSSProperties } from 'react';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';

//--------------------------------------------------------------------------------------

export type GeneralIconType =
  | 'account'
  | 'api-docs'
  | 'calender'
  | 'uploads'
  | 'surveys'
  | 'recycling'
  | 'cart'
  | 'add'
  | 'delete'
  | 'remove'
  | 'confirm'
  | 'microscope'
  | 'emissions'
  | 'share'
  | 'more'
  | 'funding'
  | 'heart'
  | 'homepage'
  | 'marketplace'
  | 'playground'
  | 'search'
  | 'transfer'
  | 'my-wallet'
  | 'inventory'
  | 'my-offsets'
  | 'calculate'
  | 'world'
  | 'offset'
  | 'goals'
  | 'emitiqLogo-outlined';

export default function GeneralIcon({
  type,
  size = 'sm',
  //TODO: remove this prop
  //eslint-disable-next-line
  mode = 'dark',
  style
}: {
  type: GeneralIconType;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  mode?: 'dark' | 'light' | 'grey';
  style?: CSSProperties;
}) {
  const themeMode = useSettingsStore((state) => state.themeMode);

  const coefficients = {
    xs: 0.45,
    sm: 0.7,
    md: 1,
    lg: 1.5,
    xl: 2
  };
  const width = Math.floor(40 * coefficients[size]);
  const height = Math.floor(45 * coefficients[size]);
  let url;

  if (themeMode === 'dark') {
    url = `url(/static/icons/general/dark/${type}.svg)`;
  }

  if (themeMode === 'light') {
    url = `url(/static/icons/general/light/${type}.svg)`;
  }

  return (
    <div
      style={{
        ...style,
        backgroundImage: url,
        backgroundPosition: 'center, center',
        backgroundSize: `${width}px ${height}px, ${width}px ${height}px`,
        backgroundRepeat: 'no-repeat, no-repeat',
        width: width,
        height: height,
        minWidth: width,
        minHeight: height,
        maxWidth: width,
        maxHeight: height
      }}
    />
  );
}
