import { MutableRefObject } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { MeasurementStatesEnum } from 'src/__apolloGenerated__/graphql';
import CarbonUnit from 'src/components/carbon/atoms/CarbonUnit';
import { MeasurementStateChip } from 'src/components/carbon/atoms/MeasurementStateChip';
import { ActiveNodeType } from 'src/components/carbon/organisms/@types';
import OverflowTypography from 'src/components/carbon/organisms/FootprintTree/FootprintMeasurement/OverflowTypography';
import Chip from 'src/components/core/atoms/Chip';
import EditableValueDisplay from 'src/components/core/atoms/EditableValueDisplay';
import { BRANCH_TYPE, updateRefs } from 'src/config';
import { fEnum } from 'src/utils/format';
import { create } from 'zustand';
import dayjs from 'dayjs';
import { fLocalDate } from 'src/utils/format';
import { getPalette } from 'src/theme/palette';
import IconButton from 'src/components/core/atoms/IconButton';

export type ActiveViewType = 'Emissions' | 'Factors';
export type SelectedRowType = {
  //branches that are directly selected (not inherited through a parent)
  branches: {
    [branchIdentifier: string]: {
      path: string;
      userPermissions: {
        read?: boolean;
        edit?: boolean;
        create?: boolean;
        delete?: boolean;
      };
    };
  };

  //measurements that are directly selected (not inherited through a parent)
  measurements: {
    [measurementIdentifier: string]: {
      state: MeasurementStatesEnum;
      parentBranchIdentifier: string;
      parentBranchPath: string;
      userPermissions: {
        read?: boolean;
        edit?: boolean;
        create?: boolean;
        delete?: boolean;
      };
    };
  };
};

type TreeColumnType = {
  columnLabel: string;
  onClick?: () => void;
  renderCell: (...props: any) => JSX.Element | string;
  width: number;
};
export type TreeColumnKeyType =
  | 'name'
  | 'kgCo2e'
  | 'goalOrActions'
  | 'scope1'
  | 'scope2'
  | 'scope3'
  | 'dateOfMeasurement'
  | 'ghgCategory'
  | 'state'
  | 'emissionFactor'
  | 'referenceSource'
  | 'referenceOrganization'
  | 'referenceCountry'
  | 'referenceUrl'
  | 'referenceYear'
  | 'classificationPath'
  | 'value'
  | 'valueUnit';

export type MeasureStoreType = {
  // Topbar Filters
  selectedRangeType: 'Month' | 'Quarter' | 'Year' | 'Custom';
  setSelectedRangeType: (
    newState: 'Month' | 'Quarter' | 'Year' | 'Custom'
  ) => void;
  selectedStartDate: Date;
  setSelectedStartDate: (newState: Date) => void;
  selectedEndDate: Date;
  setSelectedEndDate: (newState: Date) => void;
  selectedFilters: MeasurementStatesEnum[];
  setSelectedFilters: (newState: MeasurementStatesEnum[]) => void;
  isDetailViewExpanded: boolean;
  setIsDetailViewExpanded: (newState: boolean) => void;

  // Active View (Emissions or Factors)
  activeView: ActiveViewType;
  setActiveView: (newState: ActiveViewType) => void;

  // Tree Columns
  lockedColumnsWidth: number;
  unlockedColumnsWidth: number;
  lockedColumnKeys: TreeColumnKeyType[];
  setLockedColumnKeys: (newState: TreeColumnKeyType[]) => void;
  unlockedColumnKeys: TreeColumnKeyType[];
  setUnlockedColumnKeys: (newState: TreeColumnKeyType[]) => void;
  sortOrder: { [key in TreeColumnKeyType]?: boolean } | undefined;
  setSortOrder: (
    newState: { [key in TreeColumnKeyType]?: boolean } | undefined
  ) => void;
  sortOrderColumn: TreeColumnKeyType | undefined;
  setSortOrderColumn: (
    newState: TreeColumnKeyType | undefined
  ) => void;

  // Tree Data
  rootNodeId: string;
  setRootNodeId: (newState: string) => void;
  rowRefs: MutableRefObject<object>;
  measurementRefs: MutableRefObject<object>;
  reorderDropzoneRefs: MutableRefObject<object>;
  isReorderingDone: boolean;
  setIsReorderingDone: (newState: boolean) => void;
  activeNode: ActiveNodeType;
  setActiveNode: (newState: ActiveNodeType) => void;
  toggleNode: (pathToNode: string) => void;
  openNode: (pathToNode: string) => void;
  pathListOfOpenNodes: Set<string>;
  selectedRows: SelectedRowType;
  setSelectedRows: (newState: SelectedRowType) => void;
  isCreatingBranch: boolean;
  setIsCreatingBranch: (newState: boolean) => void;
  isReadyToShow: boolean;
  setIsReadyToShow: (newState: boolean) => void;

  // Dialog
  addNewMeasurementDialogOpen: boolean;
  setAddNewMeasurementDialogOpen: (newState: boolean) => void;
};

//add green slash type after branch name
export const BRANCH_TAGS = {
  [BRANCH_TYPE.SURVEY]: 'Survey',
  [BRANCH_TYPE.IMAGE]: 'Receipt',
  [BRANCH_TYPE.CSV]: BRANCH_TYPE.CSV
};
export const MEASUREMENT = 'measurement';
export const SURVEY_MEASUREMENT = 'surveyMeasurement';

export const CHECKBOX_WIDTH = '40px';
export const BRANCH_TAG_WIDTH = '60px';

const defaultCellRenderer = ({ value }: any) => {
  return <OverflowTypography text={value} />;
};

export const RIGHT_MARGIN = 100;
export const RIGHT_MARGIN_PX = '100px';
export const DEFAULT_COLUMN_WIDTH = 175;
export const AVAILABLE_UNLOCKED_COLUMNS: {
  [key in TreeColumnKeyType]?: TreeColumnType;
} = {
  kgCo2e: {
    columnLabel: 'Footprint',
    width: 220,
    renderCell({ ...props }: any) {
      const { value } = props;
      if (Number(value) === 0) {
        return '';
      } else {
        return (
          <CarbonUnit
            emissionValueInKg={Number(value)}
            omitCo2eFromUnit
            variant="body2"
          />
        );
      }
    }
  },
  goalOrActions: {
    columnLabel: 'Goal/Actions',
    width: 220,
    renderCell({ ...props }: any) {
      const {
        branch,
        goal,
        measurement,
        rowRefs,
        setIsEditing,
        setCreateGoalDialogOpen,
        themeMode
      } = props;
      const palette = getPalette(themeMode);

      if (goal && !measurement) {
        return (
          <div className="max-w-full">
            <EditableValueDisplay
              backgroundColor={palette.background.default}
              onClick={
                branch
                  ? (e) => {
                      e.stopPropagation();
                      if (goal) {
                        setIsEditing(true);
                      } else {
                        setIsEditing(false);
                      }
                      setCreateGoalDialogOpen(true);
                    }
                  : undefined
              }
            >
              <div
                className="overflow-hidden text-ellipsis whitespace-nowrap text-left"
                style={{
                  color: palette.text.primaryAlt1
                }}
              >
                {goal?.title}
              </div>
            </EditableValueDisplay>
          </div>
        );
      } else if (!goal && !measurement) {
        return (
          <div
            ref={(ref) => {
              if (ref) {
                updateRefs(
                  rowRefs,
                  'expandRow',
                  ref,
                  branch?.identifier
                );
              }
            }}
            className="hidden w-full justify-start"
          >
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                if (goal) {
                  setIsEditing(true);
                } else {
                  setIsEditing(false);
                }
                setCreateGoalDialogOpen(true);
              }}
            >
              <BsPlusLg
                style={{
                  color: palette.text.primary,
                  transform: 'scale(0.8)'
                }}
              />
            </IconButton>
          </div>
        );
      } else {
        return null;
      }
    }
  },
  emissionFactor: {
    columnLabel: 'Emission factor',
    width: 220,
    renderCell({ value }: any) {
      return <OverflowTypography text={value?.name} />;
    }
  },
  ghgCategory: {
    columnLabel: 'GHG Category',
    width: 220,
    renderCell({ value }) {
      return <OverflowTypography text={fEnum(value)} />;
    }
  },
  state: {
    columnLabel: 'State',
    width: 220,
    renderCell({ branch, value }: any) {
      if (branch?.publicAggregateMeasurementsAmount?.totalCount > 0) {
        return (
          <MeasurementStateChip
            state={MeasurementStatesEnum.Public}
          />
        );
      }
      if (branch?.isFailed) {
        return <Chip label="Failed" color="error" />;
      } else {
        return (
          value && (
            <MeasurementStateChip
              state={value as MeasurementStatesEnum}
            />
          )
        );
      }
    }
  },
  dateOfMeasurement: {
    columnLabel: 'Date',
    width: 220,
    renderCell({ value }: any) {
      return (
        <OverflowTypography
          text={value ? fLocalDate(dayjs(value)) : value}
        />
      );
    }
  },
  scope1: {
    columnLabel: 'Scope 1',
    width: 220,
    renderCell({ value }: any) {
      if (Number(value) === 0) {
        return '';
      } else {
        return (
          <CarbonUnit
            emissionValueInKg={Number(value)}
            omitCo2eFromUnit
            variant="body2"
          />
        );
      }
    }
  },
  scope2: {
    columnLabel: 'Scope 2',
    width: 220,
    renderCell({ value }: any) {
      if (Number(value) === 0) {
        return '';
      } else {
        return (
          <CarbonUnit
            emissionValueInKg={Number(value)}
            omitCo2eFromUnit
            variant="body2"
          />
        );
      }
    }
  },
  scope3: {
    columnLabel: 'Scope 3',
    width: 220,
    renderCell({ value }: any) {
      if (Number(value) === 0) {
        return '';
      } else {
        return (
          <CarbonUnit
            emissionValueInKg={Number(value)}
            omitCo2eFromUnit
            variant="body2"
          />
        );
      }
    }
  },
  referenceSource: {
    columnLabel: 'Reference source',
    width: 220,
    renderCell: defaultCellRenderer
  },
  referenceOrganization: {
    columnLabel: 'Reference organization',
    width: 220,
    renderCell: defaultCellRenderer
  },
  referenceCountry: {
    columnLabel: 'Reference country',
    width: 220,
    renderCell: defaultCellRenderer
  },
  referenceUrl: {
    columnLabel: 'Reference URL',
    width: 220,
    renderCell: defaultCellRenderer
  },
  referenceYear: {
    columnLabel: 'Reference year',
    width: 220,
    renderCell: defaultCellRenderer
  },
  classificationPath: {
    columnLabel: 'Classification path',
    width: 220,
    renderCell: defaultCellRenderer
  },
  value: {
    columnLabel: 'Value',
    width: 220,
    renderCell: defaultCellRenderer
  },
  valueUnit: {
    columnLabel: 'Value Unit',
    width: 220,
    renderCell: defaultCellRenderer
  }
};

export const AVAILABLE_LOCKED_COLUMNS: {
  [key in TreeColumnKeyType]?: TreeColumnType;
} = {
  name: {
    columnLabel: 'Name',
    width: 400,
    renderCell: defaultCellRenderer
  }
};

const lockedColumnKeysInitial = ['name'] as TreeColumnKeyType[];
const unlockedColumnKeysInitial = [
  'kgCo2e',
  'state',
  'emissionFactor',
  'ghgCategory',
  'dateOfMeasurement',
  'goalOrActions'
] as TreeColumnKeyType[];
export const DEFAULT_UNLOCKED_COLUMN_KEY = 'kgCo2e';
export const UNLOCKED_COLUMN_KEY_SCHEMA_MATCH = {
  // frontend -> server
  kgCo2e: 'FOOTPRINT',
  state: 'STATE',
  emissionFactor: 'EMISSION_FACTOR',
  ghgCategory: 'GHG_CATEGORY',
  dateOfMeasurement: 'DATE',
  goalOrActions: 'GOALS_ACTIONS',
  scope1: 'SCOPE_1',
  scope2: 'SCOPE_2',
  scope3: 'SCOPE_3',
  referenceSource: 'REFERENCE_SOURCE',
  referenceOrganization: 'REFERENCE_ORGANIZATION',
  referenceCountry: 'REFERENCE_COUNTRY',
  referenceUrl: 'REFERENCE_URL',
  referenceYear: 'REFERENCE_YEAR',
  classificationPath: 'CLASSIFICATION_PATH',
  value: 'VALUE',
  valueUnit: 'VALUE_UNIT',

  // server -> frontend
  FOOTPRINT: 'kgCo2e',
  GOALS_ACTIONS: 'goalOrActions',
  EMISSION_FACTOR: 'emissionFactor',
  STATE: 'state',
  DATE: 'dateOfMeasurement',
  GHG_CATEGORY: 'ghgCategory',
  SCOPE_1: 'scope1',
  SCOPE_2: 'scope2',
  SCOPE_3: 'scope3',
  REFERENCE_SOURCE: 'referenceSource',
  REFERENCE_ORGANIZATION: 'referenceOrganization',
  REFERENCE_COUNTRY: 'referenceCountry',
  REFERENCE_URL: 'referenceUrl',
  REFERENCE_YEAR: 'referenceYear',
  CLASSIFICATION_PATH: 'classificationPath',
  VALUE: 'value',
  VALUE_UNIT: 'valueUnit'
};

const compare = (key1: string, key2: string) => {
  // sort by order of keys in AVAILABLE_UNLOCKED_COLUMNS
  const index1 = Object.keys(AVAILABLE_UNLOCKED_COLUMNS).indexOf(
    key1
  );
  const index2 = Object.keys(AVAILABLE_UNLOCKED_COLUMNS).indexOf(
    key2
  );
  return index1 - index2;
};

const useMeasureStore = create<MeasureStoreType>((set, get) => ({
  selectedRangeType: 'Month',
  setSelectedRangeType: (newRangeType) => {
    set({ selectedRangeType: newRangeType });
  },
  selectedStartDate: null,
  setSelectedStartDate: (newDate) => {
    set({ selectedStartDate: newDate });
  },
  selectedEndDate: null,
  setSelectedEndDate: (newDate) => {
    set({ selectedEndDate: newDate });
  },
  isDetailViewExpanded: false,
  setIsDetailViewExpanded: (newState) => {
    set({ isDetailViewExpanded: newState });
  },
  selectedFilters: Object.values(MeasurementStatesEnum),
  setSelectedFilters: (newMeasurementStatesList) => {
    set({ selectedFilters: newMeasurementStatesList });
  },
  lockedColumnKeys: lockedColumnKeysInitial,
  setLockedColumnKeys: (newLockedColumnKeys) => {
    set({
      lockedColumnKeys: newLockedColumnKeys,
      lockedColumnsWidth: newLockedColumnKeys
        .map((key) => AVAILABLE_LOCKED_COLUMNS[key].width)
        .reduce((prev, next) => prev + next)
    });
  },
  unlockedColumnKeys: unlockedColumnKeysInitial,
  setUnlockedColumnKeys: (newUnlockedColumnKeys) => {
    if (newUnlockedColumnKeys?.length) {
      set({
        unlockedColumnKeys: newUnlockedColumnKeys.sort(compare),
        unlockedColumnsWidth: newUnlockedColumnKeys
          .map((key) => AVAILABLE_UNLOCKED_COLUMNS[key].width)
          .reduce((prev, next) => prev + next)
      });
    } else {
      set({
        unlockedColumnKeys: [DEFAULT_UNLOCKED_COLUMN_KEY],
        unlockedColumnsWidth:
          AVAILABLE_UNLOCKED_COLUMNS[DEFAULT_UNLOCKED_COLUMN_KEY]
            .width
      });
    }
  },
  lockedColumnsWidth: lockedColumnKeysInitial
    .map((key) => AVAILABLE_LOCKED_COLUMNS[key].width)
    .reduce((prev, next) => prev + next),
  unlockedColumnsWidth: unlockedColumnKeysInitial
    .map((key) => AVAILABLE_UNLOCKED_COLUMNS[key].width)
    .reduce((prev, next) => prev + next),
  rowRefs: { current: {} },
  sortOrder: undefined,
  setSortOrder: (newState) => {
    set(() => ({ sortOrder: newState }));
  },
  sortOrderColumn: undefined,
  setSortOrderColumn: (newState) => {
    set(() => ({ sortOrderColumn: newState }));
  },
  reorderDropzoneRefs: { current: {} },
  isReorderingDone: true,
  setIsReorderingDone: (newState) => {
    set(() => ({ isReorderingDone: newState }));
  },
  // setRowRefs: (ref) => {
  //   set({ rowRefs: ref });
  // },
  measurementRefs: { current: {} },
  setMeasurementRefs: (ref) => {
    set({ measurementRefs: ref });
  },
  activeNode: { nodeId: null, type: null, path: null },
  setActiveNode: (newActiveNode) => {
    // set((currentStore) => {
    //     currentStore;
    //   return { activeNode: newActiveNode };
    // });
    if (newActiveNode.path) {
      get().openNode(newActiveNode.path);
    }
    set(() => ({ activeNode: newActiveNode }));
  },
  toggleNode: (pathToNode) => {
    set((store) => {
      if (store.pathListOfOpenNodes.has(pathToNode)) {
        // CLOSE: direct path
        store.pathListOfOpenNodes.forEach((value) => {
          // search for open children and close them
          if (value.startsWith(pathToNode)) {
            store.pathListOfOpenNodes.delete(value);
          }
        });
      } else {
        let found = false;
        // CLOSE: indirect path
        store.pathListOfOpenNodes.forEach((value) => {
          // search if node/children is open
          if (value.startsWith(pathToNode)) {
            found = true;
            store.pathListOfOpenNodes.delete(value);
          }
        });
        if (!found) {
          // OPEN
          pathToNode && store.pathListOfOpenNodes.add(pathToNode);
        }
      }
      return { pathListOfOpenNodes: store.pathListOfOpenNodes };
    });
  },
  openNode: (pathToNode: string) => {
    if (pathToNode) {
      const pathParts = pathToNode
        .split('.')
        .filter((item) => item)
        .map((pathPart) => pathPart + '.');
      const pathList = [];
      pathParts.forEach((path, idx) => {
        idx
          ? pathList.push(pathList[pathList.length - 1] + path)
          : pathList.push(path);
      });

      set((store) => ({
        pathListOfOpenNodes:
          pathList.length > 1
            ? new Set([
                ...pathList,
                ...store.pathListOfOpenNodes.values()
              ])
            : store.pathListOfOpenNodes
      }));
    }
  },
  pathListOfOpenNodes: new Set(['1.']),
  selectedRows: { branches: {}, measurements: {} },
  setSelectedRows: (newState) => {
    set(() => ({ selectedRows: newState }));
  },
  isCreatingBranch: false,
  setIsCreatingBranch: (newState) => {
    set(() => ({ isCreatingBranch: newState }));
  },
  // The "isReadyToShow" is used to show Skeleton or animation that we need to wait until a chain of operations will be finished,
  // like, creating nodes, setting proper states, etc.
  // You can't rely on 'loading' variable from Apollo because there are some situations when a query is done ('loading' is set to true),
  // but we still need to wait for other operations.
  isReadyToShow: true,
  setIsReadyToShow: (newState) => {
    set(() => ({ isReadyToShow: newState }));
  },
  rootNodeId: null,
  setRootNodeId: (newState) => {
    set(() => ({ rootNodeId: newState }));
  },
  activeView: 'Emissions',
  setActiveView: (newState) => {
    set(() => ({ activeView: newState }));
  },
  addNewMeasurementDialogOpen: false,
  setAddNewMeasurementDialogOpen: (newState) => {
    set(() => ({ addNewMeasurementDialogOpen: newState }));
  }
}));

export default useMeasureStore;
