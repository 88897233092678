import {
  IntercomContextValues,
  useIntercom
} from 'react-use-intercom';
import React from 'react';

interface IProps {
  setUseIntercomRef: (showIntercom: IntercomContextValues) => void;
}

const InnerIntercomUtilsConfigurator: React.FC<IProps> = (
  props: IProps
) => {
  props.setUseIntercomRef(useIntercom());
  return null;
};

let intercom: IntercomContextValues;
const setUseIntercomRef = (
  useIntercomRefProp: IntercomContextValues
) => {
  intercom = useIntercomRefProp;
};

export const IntercomUtilsConfigurator = () => {
  return (
    <InnerIntercomUtilsConfigurator
      setUseIntercomRef={setUseIntercomRef}
    />
  );
};

export const INTERCOM_EVENTS = {
  TALK_WITH_SALES: 'talkWithSales',
  BUG_ENCOUNTERED: 'bugEncountered'
};

export const INTERCOM_ARTICLES = {
  'Getting Started': 9355007,
  MEASURE: 'id',
  REDUCE: 'id',
  SHARE: 'id',
  BULK: 'id'
};

const getIntercom = () => {
  return intercom;
};
export default getIntercom;
