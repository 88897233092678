import { motion as Motion } from 'framer-motion';
import Icon from './Icon';

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div style={{ transform: 'translate(0%%, -100%)' }}>
        <Motion.div
          initial={{ rotateZ: 0 }}
          animate={{ rotateZ: 360 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeatDelay: 0.3,
            repeat: Infinity
          }}
        >
          <Icon />
        </Motion.div>
      </div>
    </div>
  );
}
