import { useRef } from 'react';
import { getTailwindTheme } from 'src/config';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';

export default function EditableValueDisplay({
  children,
  prefixText = null,
  tabIndex = 0,
  warningStatement = null,
  highlight = false,
  onClick = null,
  onMouseEnter = null,
  disabled = false,
  backgroundColor,
  onMouseLeave = null
}: {
  children: React.ReactNode;
  prefixText?: string | null;
  tabIndex?: number;
  warningStatement?: boolean | null;
  highlight?: boolean;
  onClick?: (e) => void;
  onMouseEnter?: (e) => void;
  disabled?: boolean;
  backgroundColor?: string;
  onMouseLeave?: (e) => void;
}) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  backgroundColor = backgroundColor || palette.background.paper;
  const theme = getTailwindTheme();
  const textColor = highlight
    ? palette.primary.main
    : palette.text.primary;
  const ref = useRef(null);

  return (
    <div
      ref={ref}
      tabIndex={tabIndex}
      onClick={
        disabled
          ? null
          : (e) => {
              onClick && onClick(e);
            }
      }
      onMouseEnter={
        disabled
          ? null
          : (e) => {
              onMouseEnter && onMouseEnter(e);
            }
      }
      onMouseLeave={
        disabled
          ? null
          : (e) => {
              onMouseLeave && onMouseLeave(e);

              // if ref is focused, unfocus
              if (ref.current === document.activeElement) {
                ref.current.blur();
              }
            }
      }
      // If the element is focused, and the user presses enter, the onClick function will be called.
      onKeyDown={
        disabled
          ? null
          : (e) => {
              // check if element is focused

              if (e.key === 'Enter') {
                onClick && onClick(e);
              }
            }
      }
      className="flex rounded-lg border px-4 hover:border-[color:--editable-value-display-border-color-hover] hover:text-[color:--editable-value-display-color-hover] focus:border-[color:--editable-value-display-border-color-focus] focus:outline-[--editable-value-display-outline-focus]"
      style={{
        ...typography.caption,
        color: disabled
          ? palette.text.primaryAlt2
          : warningStatement === true
            ? palette.warning.dark
            : textColor,
        borderColor: palette.border.main,
        backgroundColor: backgroundColor,
        cursor: disabled ? 'inherit' : 'pointer',
        maxHeight: theme.height['small-button'],
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.height['small-button'],
        transition: 'all 0.2s ease-in-out',
        ['--editable-value-display-color-hover' as string]: !disabled
          ? warningStatement === true
            ? palette.warning.dark
            : textColor
          : null,
        ['--editable-value-display-border-color-hover' as string]:
          !disabled ? palette.info.main : null,
        ['--editable-value-display-border-color-focus' as string]:
          disabled ? null : palette.info.main,
        ['--editable-value-display-outline-focus' as string]: disabled
          ? null
          : 'none'
      }}
    >
      {prefixText ? (
        <div
          className="inline"
          style={{ color: palette.text.primaryAlt2 }}
        >
          {prefixText}:&nbsp;
        </div>
      ) : (
        ''
      )}
      {children}
    </div>
  );
}
