import { createSlice } from '@reduxjs/toolkit';
import storage from './storage';

// ----------------------------------------------------------------------

const persistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const slice = createSlice({
  name: 'default',
  initialState: {},
  reducers: {}
});

const reducer = slice.reducer;
export type RootState = ReturnType<typeof reducer>;
export { persistConfig, reducer };
