// ----------------------------------------------------------------------

export default function Icon({
  size = 'sm',
  className = ''
}: {
  size?: 'sm' | 'md' | 'lg' | 'xs';
  className?: string;
}) {
  const coefficients = {
    xs: 0.45,
    sm: 0.7,
    md: 1,
    lg: 1.5,
    xl: 2
  };
  const width = Math.floor(40 * coefficients[size]);
  const height = Math.floor(45 * coefficients[size]);

  return (
    <div
      className={className}
      style={{
        backgroundImage: 'url(/static/emitiq/eiq_icon_blue.svg)',
        backgroundPosition: 'center, center',
        backgroundSize: `${width}px ${height}px, ${width}px ${height}px`,
        backgroundRepeat: 'no-repeat, no-repeat',
        width: width,
        height: height,
        minWidth: width,
        minHeight: height,
        maxWidth: width,
        maxHeight: height
      }}
    />
  );
}
