import { MeasurementStatesEnum } from 'src/__apolloGenerated__/graphql';

export function MeasurementStateChip({
  state
}: {
  state: MeasurementStatesEnum;
}) {
  const chipStateColors = {
    [MeasurementStatesEnum.Recorded]: 'bg-success/20',
    [MeasurementStatesEnum.Public]: 'bg-primary/20',
    [MeasurementStatesEnum.ToReview]: 'bg-warning/20',
    [MeasurementStatesEnum.Archived]: 'bg-destructive/20',
    [MeasurementStatesEnum.Failed]: 'bg-destructive/20',
    [MeasurementStatesEnum.Excluded]: 'bg-muted/20'
  };
  const textColors = {
    [MeasurementStatesEnum.Recorded]: 'text-success-foreground',
    [MeasurementStatesEnum.Public]: 'text-primary',
    [MeasurementStatesEnum.ToReview]: 'text-warning-foreground',
    [MeasurementStatesEnum.Archived]: 'text-destructive-foreground',
    [MeasurementStatesEnum.Failed]: 'text-destructive-foreground',
    [MeasurementStatesEnum.Excluded]: 'text-muted-foreground'
  };

  return (
    <div
      className={
        'flex items-center rounded-full px-md py-1 ' +
        chipStateColors[state]
      }
    >
      <p className={'body2 ' + textColors[state]}>
        {state === MeasurementStatesEnum.ToReview
          ? 'needs review'
          : state.toLowerCase()}
      </p>
    </div>
  );
}
