import { useEffect, useRef, useState } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'src/components/shad-base/tooltip';

export default function OverflowTypography({
  text,
  tooltipText,
  forceTooltip = false,
  typographySx
}: {
  text: string | JSX.Element;
  tooltipText?: string | JSX.Element;
  forceTooltip?: boolean;
  typographySx?: object;
}) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef(null);
  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth >
        textElementRef.current.clientWidth
    );
  }, []);
  return isOverflowed || forceTooltip ? (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <p
            ref={textElementRef}
            className="body2 max-w-full truncate text-nowrap text-left"
            style={{
              ...typographySx
            }}
          >
            {text}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          {tooltipText ? tooltipText : text}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <p
      ref={textElementRef}
      className="body2 max-w-full truncate text-nowrap text-left"
      style={{
        ...typographySx
      }}
    >
      {text}
    </p>
  );
}
