// import { Breakpoint } from '@mui/material';
// import { Variant } from '@mui/material/styles/createTypography';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/system';
// import { remToPx } from './format';

// ----------------------------------------------------------------------

// export default function GetFontValue(variant: Variant) {
//   const theme = useTheme();
//   const breakpoints = useWidth();

//   const key = theme.breakpoints.up(
//     breakpoints === 'xl' ? 'lg' : breakpoints
//   );

//   const hasResponsive =
//     variant === 'h1' ||
//     variant === 'h2' ||
//     variant === 'h3' ||
//     variant === 'h4' ||
//     variant === 'h5' ||
//     variant === 'h6';

//   const getFont =
//     (hasResponsive && theme.typography[variant][key]) ||
//     theme.typography[variant];

//   const fontSize = remToPx(getFont.fontSize);
//   const lineHeight =
//     Number(theme.typography[variant].lineHeight) * fontSize;
//   const { fontWeight, letterSpacing } = theme.typography[variant];

//   return { fontSize, lineHeight, fontWeight, letterSpacing };
// }

// ----------------------------------------------------------------------

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({
  tablet,
  mobile
}: {
  tablet: number;
  mobile: number;
}) {
  tablet; // lint: unused variable dumb fix
  return {
    // '@media (max-width:1200px)': {
    //   fontSize: pxToRem(tablet)
    // },
    '@media (maxWidth:500px)': {
      fontSize: pxToRem(mobile)
    }
  };
}

// ----------------------------------------------------------------------

// function useWidth() {
//   const theme = useTheme();
//   const keys = [...theme.breakpoints.keys].reverse();
//   return (
//     keys.reduce((output, key) => {
//       // eslint-disable-next-line react-hooks/rules-of-hooks
//       const matches = useResponsive('up', key);
//       return !output && matches ? key : output;
//     }, null) || 'xs'
//   );
// }

// ----------------------------------------------------------------------

// type Query = 'up' | 'down' | 'between' | 'only';
// type Key = Breakpoint | number;
// type Start = Breakpoint | number;
// type End = Breakpoint | number;

// function useResponsive(
//   query: Query,
//   key?: Key,
//   start?: Start,
//   end?: End
// ) {
//   const theme = useTheme();

//   const mediaUp = useMediaQuery(theme.breakpoints.up(key as Key));

//   const mediaDown = useMediaQuery(theme.breakpoints.down(key as Key));

//   const mediaBetween = useMediaQuery(
//     theme.breakpoints.between(start as Start, end as End)
//   );

//   const mediaOnly = useMediaQuery(
//     theme.breakpoints.only(key as Breakpoint)
//   );

//   if (query === 'up') {
//     return mediaUp;
//   }

//   if (query === 'down') {
//     return mediaDown;
//   }

//   if (query === 'between') {
//     return mediaBetween;
//   }

//   if (query === 'only') {
//     return mediaOnly;
//   }
// }
