import { clsx, type ClassValue } from 'clsx';
import {
  DataCalculatorResponseFragmentFragment,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function sortDataCalculatorResponses(
  dataCalculatorResponses: DataCalculatorResponseFragmentFragment[]
) {
  let calculators = [...dataCalculatorResponses];
  calculators.sort((a, b) => {
    if (a.measurements.length > 0 && !(b.measurements.length > 0)) {
      return 1;
    } else if (
      !(a.measurements.length > 0) &&
      b.measurements.length > 0
    ) {
      return -1;
    } else {
      return 0;
    }
  });
  return calculators;
}

export function sortMeasurements(
  measurements: MeasurementFragmentFragment[]
) {
  let allMeasurements = [...measurements];
  allMeasurements.sort((a, b) => {
    if (a.startDate > b.startDate || a.startDate > b.endDate) {
      return 1;
    } else if (b.startDate > a.startDate || b.startDate > a.endDate) {
      return -1;
    } else {
      return 0;
    }
  });
  return allMeasurements;
}
