import { pxToRem, responsiveFontSizes } from '../utils/fonts';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'TTHoves-Regular';
const FONT_SECONDARY = 'TTHoves-Regular'; // eslint-disable-line

const getIntValue = (value: number) => {
  return Math.ceil(value);
};

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(72)),
    ...responsiveFontSizes({
      tablet: getIntValue(74),
      mobile: getIntValue(74)
    })
  },
  h2: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(60)),
    ...responsiveFontSizes({
      tablet: getIntValue(62),
      mobile: getIntValue(62)
    })
  },
  h3: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(46)),
    ...responsiveFontSizes({ tablet: getIntValue(50), mobile: getIntValue(50) })
  },
  h4: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(34)),
    ...responsiveFontSizes({ tablet: getIntValue(38), mobile: getIntValue(38) })
  },
  h5: {
    fontFamily: 'TTHoves-Medium',
    fontSize: pxToRem(getIntValue(28)),
    ...responsiveFontSizes({ tablet: getIntValue(28), mobile: getIntValue(28) })
  },
  h6: {
    fontFamily: 'TTHoves-Medium',
    fontSize: pxToRem(getIntValue(22)),
    ...responsiveFontSizes({ tablet: getIntValue(24), mobile: getIntValue(24) })
  },
  subtitle1: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(19)),
    lineHeight: '140%',
    ...responsiveFontSizes({ tablet: getIntValue(16), mobile: getIntValue(16) })
  },
  subtitle2: {
    fontFamily: 'TTHoves-Regular',
    fontStyle: 'normal',
    fontSize: pxToRem(getIntValue(17)),
    lineHeight: '130%',
    ...responsiveFontSizes({ tablet: getIntValue(14), mobile: getIntValue(14) })
  },
  body1: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(15)),
    lineHeight: '150%',
    ...responsiveFontSizes({ tablet: getIntValue(12), mobile: getIntValue(12) })
  },
  body2: {
    fontFamily: 'TTHoves-Regular',
    fontSize: pxToRem(getIntValue(13)),
    lineHeight: '145%',
    ...responsiveFontSizes({ tablet: getIntValue(10), mobile: getIntValue(10) })
  },
  button: {
    fontFamily: 'TTHoves-Medium',
    fontSize: pxToRem(getIntValue(15)),
    textTransform: 'none',
    lineHeight: '200%'
  },
  caption: {
    fontFamily: 'TTHoves-Medium',
    fontSize: pxToRem(getIntValue(11)),
    lineHeight: '150%',
    ...responsiveFontSizes({ tablet: 12, mobile: 12 })
  },
  overline: {
    fontFamily: 'TTHoves-Bold',
    fontStyle: 'normal',
    fontSize: pxToRem(14),
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: '200%'
  }
} as const;

export function getTypography({
  themeMode
  // palette
}: {
  themeMode: 'light' | 'dark';
//  palette: any;
}) {
  themeMode;
  // typography.subtitle1.color = palette.text.primaryAlt1;
  // typography.subtitle2.color = palette.text.primaryAlt2;
  // typography.body1.color = palette.text.primary;
  // typography.body2.color = palette.text.primaryAlt1;
  return typography;
}

export default typography;
